"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftTypeToJSON = exports.NftTypeFromJSONTyped = exports.NftTypeFromJSON = exports.NftType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var NftType;
(function (NftType) {
    NftType["INDUSTRYPASS"] = "INDUSTRY_PASS";
    NftType["INFINITEACCESS"] = "INFINITE_ACCESS";
    NftType["LOOTBOX"] = "LOOTBOX";
    NftType["BRANDMAPAUTOMINT"] = "BRAND_MAP_AUTOMINT";
    NftType["GUILDBADGEAUTOMINT"] = "GUILD_BADGE_AUTOMINT";
    NftType["OVERWOLFQUESTREWARD"] = "OVERWOLF_QUEST_REWARD";
    NftType["GUILDQUESTREWARD"] = "GUILD_QUEST_REWARD";
})(NftType = exports.NftType || (exports.NftType = {}));
function NftTypeFromJSON(json) {
    return NftTypeFromJSONTyped(json, false);
}
exports.NftTypeFromJSON = NftTypeFromJSON;
function NftTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.NftTypeFromJSONTyped = NftTypeFromJSONTyped;
function NftTypeToJSON(value) {
    return value;
}
exports.NftTypeToJSON = NftTypeToJSON;
